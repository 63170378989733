.dx-datagrid-headers {
    color: #212529 !important;
    font-weight: 600 !important;
    background-color: #9e9e9e40 !important;
    -ms-touch-action: pinch-zoom;
    touch-action: pinch-zoom;
    border-bottom: 1px solid #ddd;
}


.card-header {
    background-color: #1b1c1c;
    color: black;
    text-align: center;
}


.card-footer {
    padding: 0.5rem 1rem;
    background-color: white !important;
    border-top: 0px !important;
}